import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Box, Flex, Heading, Text, Grid, Link, List, ListItem } from '@pw/ui'

import RichText from '../components/RichText'
import '../components/Products'

import { Layout, Container } from '../components/Layout'
import Section from '../components/Layout/Section'

export const ProductCategoryTemplate = ({ data, location }) => {
  const category = data?.contentfulProductCategory || {}
  const categoryContent = category?.content?.json || {}
  const products = data?.allContentfulProduct?.edges || []
  const categories = data?.allContentfulProductCategory?.edges || []

  return (
    <Layout location={location}>
      <Container px="20">
        <Section>
          <Flex justifyContent="center">
            <Heading as="h2" script="shop">
              {category.name}
            </Heading>
          </Flex>
        </Section>

        <Flex flexDirection={{ base: 'column', md: 'row' }}>
          <Box order="2">
            <RichText body={categoryContent} />

            {!products.length && <Text>No products yet, check back soon for more!</Text>}

            {!!products.length && (
              <Grid
                templateColumns={{
                  base: '1fr',
                  sm: '1fr 1fr',
                  md: `repeat(4, 1fr)`,
                }}
                gap={6}
                pt="5"
              >
                {products.map(({ node }) => (
                  <Flex
                    key={node.id}
                    as={Link}
                    href={node?.amazonLink ?? node?.secondaryAffiliateLink}
                    target="_blank"
                    flexDirection="column"
                    justifyContent="space-between"
                    borderWidth="1px"
                    p="5"
                    borderColor="gray.100"
                  >
                    <Box flex="3">
                      <Img
                        fluid={node?.mainPhoto?.fluid}
                        style={{ maxHeight: '100%' }}
                        imgStyle={{ objectFit: 'contain' }}
                      />
                    </Box>

                    <Heading
                      flex="1"
                      display="flex"
                      alignItems="flex-end"
                      justifyContent="center"
                      as="h5"
                      fontWeight="normal"
                      textTransform="capitalize"
                      pt="5"
                    >
                      {node.title}
                    </Heading>
                  </Flex>
                ))}
              </Grid>
            )}
          </Box>

          <Box order="1" minWidth="250px" pr={{ base: 0, md: '10' }}>
            <Heading as="h3" fontWeight="normal" pb="5">
              Shop by Category
            </Heading>

            <List>
              {categories.map(({ node }) => (
                <ListItem key={node.id}>
                  <Box
                    as={node.slug === category.slug ? 'strong' : Link}
                    display="block"
                    py="2"
                    to={`/shop/${node.slug}`}
                    target="_blank"
                  >
                    {node.name}
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        </Flex>
      </Container>
    </Layout>
  )
}

export default ProductCategoryTemplate

export const pageQuery = graphql`
  query ProductCategoryBySlug($slug: String!, $productSlugs: [String!]) {
    contentfulProductCategory(slug: { eq: $slug }) {
      name
      slug
      content {
        json
      }
    }

    allContentfulProductCategory {
      edges {
        node {
          id
          slug
          name
        }
      }
    }

    allContentfulProduct(filter: { slug: { in: $productSlugs } }) {
      edges {
        node {
          ...productFields
        }
      }
    }
  }
`
