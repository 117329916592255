import { graphql } from 'gatsby'
import 'gatsby-image'

export const query = graphql`
  fragment productFields on ContentfulProduct {
    id
    slug
    title
    amazonLink
    secondaryAffiliateLink
    mainPhoto {
      fluid(maxWidth: 200, maxHeight: 200) {
        ...GatsbyContentfulFluid
      }
    }
  }

  fragment productCategoryFields on ContentfulProductCategory {
    id
    slug
    name
    mainPhoto {
      fluid(maxWidth: 600) {
        ...GatsbyContentfulFluid
      }
      file {
        details {
          size
          image {
            height
            width
          }
        }
      }
    }
  }
`
